import React from 'react'

import { Helmet } from 'react-helmet'

import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Datyk</title>
        <meta property="og:title" content="Daniel Tykriset" />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/ad066b8a-6cad-4668-b073-341cec43b3c2/0c562ca1-5f0b-40b4-b9a2-91d5697e682e?org_if_sml=1&amp;force_format=original"
        />
      </Helmet>
      <div className="home-hero">
        <div className="home-container1">
          <h1 className="home-text">
            <span className="home-text1">Hi</span>
            <span className="home-text2">,</span>
          </h1>
          <span className="home-text3">
            This is not the site you&apos;re looking for.
          </span>
        </div>
        <a href="https://tyk.no/" className="home-link">
          <img
            alt="image"
            src="https://tyk.no/assets/logo64.svg"
            className="home-image"
          />
        </a>
      </div>
    </div>
  )
}

export default Home
